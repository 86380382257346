<template>
<!-- <section id="home" class="overflow-hidden sm:grid sm:grid-cols-2 sm:items-center bg-gradient-to-r from-cyan-400 to-blue-300"> -->
<section id="home" class="w-full">
  <main class="overflow-hidden sm:grid sm:grid-cols-2 sm:items-center">
  <div class="hidden md:block p-8 md:p-12 lg:px-16 lg:py-24">
    <svg class="w-full h-[560px] absolute left-0 top-[4.1rem] z-0" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" preserveAspectRatio="none" viewBox="0 0 1440 560" >
        <g mask="url(&quot;#SvgjsMask1020&quot;)" fill="none">
            <rect width="1440" height="560" x="0" y="0"></rect>
            <path d="M1395.54-96.12C1251.18-78.49 1170.4 244.31 925.83 247.06 681.26 249.81 690.97 177.06 456.11 177.06 221.26 177.06 105.13 246.87-13.6 247.06" stroke="rgba(51,121,194,0.58)" stroke-width="2"></path>
            <path d="M1166.24-61.23C995.11-52.85 878.89 238.62 553.24 245.25 227.59 251.88 97.13 378.86-59.76 379.65" stroke="rgba(100,166, 44, 0.58)" stroke-width="2"></path>
            <path d="M1241.24-28.71C1123.9-25.65 1024.29 143.13 804.37 143.3 584.45 143.47 585.93 73.3 367.5 73.3 149.06 73.3 41.24 143.08-69.37 143.3" stroke="rgba(51,121,194,0.58)" stroke-width="2"></path>
            <path d="M1356.63-66.34C1227.75-60.81 1126.65 154.58 890.57 155.07 654.49 155.56 657.54 85.07 424.51 85.07 191.48 85.07 76.27 154.88-41.55 155.07" stroke="rgba(100,166, 44,0.58)" stroke-width="2"></path>
            <path d="M984.19-5.64C820.38 2.78 694.19 291.98 399.61 292.27 105.02 292.56-34.03 142.29-184.98 141.07" stroke="rgba(51,121,194,0.58)" stroke-width="2"></path>
        </g>
        <defs>
            <mask id="SvgjsMask1020">
                <rect width="1440" height="560" fill="#ffffff"></rect>
            </mask>
        </defs>
    </svg>
    <h1 class="text-3xl text-gray-800 font-extrabold sm:text-5xl">
      Bem Vindo
      <strong class="block font-extrabold text-sky-700">
        Planeta Piscinas
      </strong>
    </h1>
        <div class="mt-8 sm:mt-12">
          <dl class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2">
              <div data-aos="fade-up" class="flex flex-col border border-gray-300 px-4 py-8 text-center bg-white">
                  <dt class="order-last text-sm md:text-lg font-medium text-gray-500 whitespace-nowrap">
                      Construído
                  </dt>

                  <dd class="text-xl md:text-3xl font-extrabold text-sky-600">
                      {{ lamina.toFixed(0) }}m²
                  </dd>
              </div>

              <div data-aos="fade-up" data-aos-delay="200" class="flex flex-col border border-gray-300 px-4 py-8 text-center bg-[white]">
                  <dt class="order-last  text-sm md:text-lg font-medium text-gray-500">
                      Cidades Atendidas
                  </dt>
                  <dd class="text-xl md:text-3xl font-extrabold text-sky-600">
                    {{ cidades.toFixed(0) }}
                  </dd>
              </div>

              <div  data-aos="fade-up" data-aos-delay="400" class="flex flex-col border border-gray-300 px-4 py-8 text-center bg-white">
                  <dt class="order-last text-lg font-medium text-gray-500">
                    Total de Obras
                  </dt>
                  <dd class="text-xl md:text-3xl font-extrabold text-sky-600">
                    {{ total.toFixed(0) }}
                  </dd>
              </div>
          </dl>
        </div>

        <p class="mt-4 text-left sm:leading-relaxed">
          Estamos aqui para ajudá-lo a encontrar a piscina perfeita para a sua casa e para sua família. Se você estiver procurando por uma piscina de fibra, concreto ou vinil, temos uma ampla variedade de opções para atender a todas as suas necessidades.
        </p>

        <div class="mt-10 flex flex-wrap gap-4 text-center justify-center">
          <router-link to="#produtos"  class="block w-full rounded bg-sky-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-sky-700 focus:outline-none focus:ring active:bg-sky-500 sm:w-full">  
            Fazer um Orçamento
          </router-link>
          <router-link to="#produtos" class="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-sky-600 shadow hover:text-sky-700 focus:outline-none focus:ring active:text-sky-500 sm:w-full">  
            Nossos Produtos
          </router-link>
        </div>
  </div>
  <div class="flex relative">
    <video  @click="alterarSom()" autoplay muted loop id="myVideo" class="w-full h-[95vh] mt-16 object-cover sm:self-end shadow-lg"  data-aos="fade-down">
      <source src="@/assets/movie/Social_Bauru.mp4" type="video/mp4">
    </video>
    <div class="absolute bottom-0 m-auto w-full h-56 bg-gradient-to-t from-black/50">
      <div class="md:hidden w-full absolute bottom-0 pb-10 px-4">
        <h1 class="text-2xl text-white font-extrabold">
          Bem Vindo
          <strong class="block font-extrabold text-3xl">
            Planeta Piscinas
          </strong>
        </h1>

        <div class="mt-10 flex flex-wrap gap-4 text-center justify-center">
          <router-link to="#produtos" class="block w-full bg-[#cc9433]/10 border-[#cc9433] border  px-12 py-3 text-white font-bold hover:bg-[#cc9433] active:text-[#cc9433] uppercase">  
            Nossos Produtos
          </router-link>
          <button @click="alterarSom()" v-show="this.muted">
            <span class="flex items-center text-gray-300 text-xs animate-pulse">
              <svg v-if="this.muted == 1" class="h-7 w-7 p-2 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 24 24" id="meteor-icon-kit__solid-volume-mute" fill="#fff"><path d="M1.29292 20.2929C0.902398 20.6834 0.902398 21.3166 1.29292 21.7071C1.68345 22.0976 2.31661 22.0976 2.70714 21.7071L3.69966 20.7146C3.70215 20.7121 3.70463 20.7097 3.70711 20.7072L21.7071 2.70721C21.7096 2.70475 21.712 2.70227 21.7145 2.69979L22.7071 1.70711C23.0977 1.31658 23.0977 0.68342 22.7071 0.29289C22.3166 -0.097631 21.6834 -0.097631 21.2929 0.29289L13.5 8.08581V2.30132C13.5 0.55256 11.4138 -0.353996 10.1354 0.83921L4.60584 6.00011H1C0.447715 6.00011 0 6.44783 0 7.00011V15.0001C0 15.5524 0.447715 16.0001 1 16.0001H4.60584L5.11266 16.4732L1.29292 20.2929z" fill="#fff"/><path d="M8.02396 19.1904L13.5 13.7143V19.6989C13.5 21.4477 11.4138 22.3542 10.1354 21.161L8.02396 19.1904z" fill="#fff"/><path d="M17.7563 9.4581L19.2887 7.92564C19.7574 8.88434 20.0016 9.9396 20 11.0109C19.9981 12.2415 19.6718 13.4498 19.0541 14.5141C18.4363 15.5784 17.5489 16.4611 16.4813 17.0731C16.0021 17.3477 15.391 17.182 15.1164 16.7028C14.8417 16.2237 15.0075 15.6126 15.4866 15.3379C16.2492 14.9008 16.8831 14.2703 17.3243 13.5101C17.7656 12.7499 17.9986 11.8868 18 11.0078C18.0008 10.4795 17.9179 9.9566 17.7563 9.4581z" fill="#fff"/><path d="M20.7648 6.44952L20.7977 6.50611C21.5877 7.87694 22.0024 9.4318 22 11.014C21.9975 12.5962 21.578 14.1498 20.7838 15.5181C19.9895 16.8865 18.8485 18.0213 17.4759 18.8082C16.9968 19.0828 16.831 19.6939 17.1057 20.1731C17.3803 20.6522 17.9914 20.818 18.4705 20.5433C20.1482 19.5816 21.5427 18.1946 22.5135 16.5221C23.4843 14.8497 23.997 12.9509 24 11.0171C24.003 9.0833 23.4961 7.1829 22.5305 5.50745C22.4306 5.33417 22.3262 5.16393 22.2174 4.99688L20.7648 6.44952z" fill="#fff"/></svg>
              Desativar Mudo
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</main>
</section>
</template>

<script>
  import gsap from "gsap";
  export default {
    data(){
      return {
        lamina: 0,
        cidades: 0,
        total: 0,

        muted: 1
      }
    },
    methods: {
      async alterarSom(){
        const video = document.getElementById("myVideo");

        if(video.muted) {
          video.muted = false;
          this.muted = 0;
        } else {
          video.muted = true;
          this.muted = 1;
        }
        
      },
    },
    mounted(){
      gsap.to(this, { duration: 1.9, lamina: Number(9400) || 0 }),
      gsap.to(this, { duration: 1.9, cidades: Number(29) || 0 }),
      gsap.to(this, { duration: 1.9, total: Number(510) || 0 })
    }
  }
    
</script>

<style scoped>
</style>