<template>
  <div id="feedback" class="bg-[#1195BB] py-24 text-white">
    <h2 class="text-center text-4xl font-bold tracking-tight sm:text-5xl">
        FeedBack dos Clientes
    </h2>
    <p class="text-center tracking-tight sm:text-2xl">
      Não acredite apenas em nossa palavra... 
      <br class="hidden sm:block lg:hidden" />
      Leia as avaliações de nossos clientes
    </p>

    <section>
      <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div class="swiper-container-feedBack mt-12 !overflow-hidden">
            <div class="swiper-wrapper">
              <div v-for="(feedback, index) in feedbacks" :key="index" data-aos="fade-down" :data-aos-duration="3000" class="swiper-slide">
                <span class="c-card block bg-white text-black border shadow-md hover:shadow-xl rounded-lg overflow-hidden">
                      <Carrossel :index_carrossel="index" :imagens="feedback.imagens" />
                    <!-- <div class="relative pb-48 overflow-hidden"> -->
                        <!-- <img class="absolute inset-0 h-full w-full object-cover"
                            src="../assets/img/FeedBack/BRANCO_2.jpg"
                            alt=""> -->
                    <!-- </div> -->
                    <div class="p-4 text-left text-black" v-if="!!acoes">
                        <span class="text-red-500 bg-red-100 text-green-800 bg-green-200 text-orange-800 bg-orange-200 text-blue-500 bg-blue-100"></span>
                        <div class="w-full overflow-hidden flex">
                          <span v-for="(acao, index) in feedback.acoes" :key="index" :class="acoes[acao].style + ' inline-block px-2 py-1 ml-2 leading-none rounded-sm font-semibold uppercase tracking-wide text-[10px]'">
                              {{ acoes[acao].nome }}
                          </span>
                        </div>
                        <h2 class="mt-2 mb-2 font-bold">{{ feedback.cliente }}</h2>
                        <p class="text-sm h-20 overflow-y-auto">{{ feedback.depoimento }}</p>
                        <!-- <p class="text-sm text-sky-600">Leia Mais </p> -->
                    </div>
                    <div class="p-4 text-xs text-gray-700">
                        <span class="flex items-center mb-1">
                            <i class="far fa-clock fa-fw mr-2 text-gray-900"></i> {{ feedback.cidade }}, {{ feedback.estado }}
                        </span>
                        <span class="flex items-center">
                            <i class="far fa-address-card fa-fw text-gray-900 mr-2"></i> {{ feedback.bairro }}
                        </span>
                    </div>
                    <div class="p-4 flex items-center text-sm text-gray-600">
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :class="feedback.avaliacao  >= 1 ? 'text-yellow-500 h-4 w-4 fill-current ' : 'text-gray-400 h-4 w-4 fill-current' ">
                        <path d="M8.128 19.825a1.586 1.586 0 0 1-1.643-.117 1.543 1.543 0 0 1-.53-.662 1.515 1.515 0 0 1-.096-.837l.736-4.247-3.13-3a1.514 1.514 0 0 1-.39-1.569c.09-.271.254-.513.475-.698.22-.185.49-.306.776-.35L8.66 7.73l1.925-3.862c.128-.26.328-.48.577-.633a1.584 1.584 0 0 1 1.662 0c.25.153.45.373.577.633l1.925 3.847 4.334.615c.29.042.562.162.785.348.224.186.39.43.48.704a1.514 1.514 0 0 1-.404 1.58l-3.13 3 .736 4.247c.047.282.014.572-.096.837-.111.265-.294.494-.53.662a1.582 1.582 0 0 1-1.643.117l-3.865-2-3.865 2z"></path>
                      </svg>
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :class="feedback.avaliacao >= 2 ? 'text-yellow-500 h-4 w-4 fill-current ' : 'text-gray-400 h-4 w-4 fill-current' ">
                        <path d="M8.128 19.825a1.586 1.586 0 0 1-1.643-.117 1.543 1.543 0 0 1-.53-.662 1.515 1.515 0 0 1-.096-.837l.736-4.247-3.13-3a1.514 1.514 0 0 1-.39-1.569c.09-.271.254-.513.475-.698.22-.185.49-.306.776-.35L8.66 7.73l1.925-3.862c.128-.26.328-.48.577-.633a1.584 1.584 0 0 1 1.662 0c.25.153.45.373.577.633l1.925 3.847 4.334.615c.29.042.562.162.785.348.224.186.39.43.48.704a1.514 1.514 0 0 1-.404 1.58l-3.13 3 .736 4.247c.047.282.014.572-.096.837-.111.265-.294.494-.53.662a1.582 1.582 0 0 1-1.643.117l-3.865-2-3.865 2z"></path>
                      </svg>
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :class="feedback.avaliacao >= 3 ? 'text-yellow-500 h-4 w-4 fill-current ' : 'text-gray-400 h-4 w-4 fill-current' ">
                        <path d="M8.128 19.825a1.586 1.586 0 0 1-1.643-.117 1.543 1.543 0 0 1-.53-.662 1.515 1.515 0 0 1-.096-.837l.736-4.247-3.13-3a1.514 1.514 0 0 1-.39-1.569c.09-.271.254-.513.475-.698.22-.185.49-.306.776-.35L8.66 7.73l1.925-3.862c.128-.26.328-.48.577-.633a1.584 1.584 0 0 1 1.662 0c.25.153.45.373.577.633l1.925 3.847 4.334.615c.29.042.562.162.785.348.224.186.39.43.48.704a1.514 1.514 0 0 1-.404 1.58l-3.13 3 .736 4.247c.047.282.014.572-.096.837-.111.265-.294.494-.53.662a1.582 1.582 0 0 1-1.643.117l-3.865-2-3.865 2z"></path>
                      </svg>
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :class="feedback.avaliacao >= 4 ? 'text-yellow-500 h-4 w-4 fill-current ' : 'text-gray-400 h-4 w-4 fill-current' ">
                        <path d="M8.128 19.825a1.586 1.586 0 0 1-1.643-.117 1.543 1.543 0 0 1-.53-.662 1.515 1.515 0 0 1-.096-.837l.736-4.247-3.13-3a1.514 1.514 0 0 1-.39-1.569c.09-.271.254-.513.475-.698.22-.185.49-.306.776-.35L8.66 7.73l1.925-3.862c.128-.26.328-.48.577-.633a1.584 1.584 0 0 1 1.662 0c.25.153.45.373.577.633l1.925 3.847 4.334.615c.29.042.562.162.785.348.224.186.39.43.48.704a1.514 1.514 0 0 1-.404 1.58l-3.13 3 .736 4.247c.047.282.014.572-.096.837-.111.265-.294.494-.53.662a1.582 1.582 0 0 1-1.643.117l-3.865-2-3.865 2z"></path>
                      </svg>
                      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :class="feedback.avaliacao >= 5 ? 'text-yellow-500 h-4 w-4 fill-current ' : 'text-gray-400 h-4 w-4 fill-current' ">
                        <path d="M8.128 19.825a1.586 1.586 0 0 1-1.643-.117 1.543 1.543 0 0 1-.53-.662 1.515 1.515 0 0 1-.096-.837l.736-4.247-3.13-3a1.514 1.514 0 0 1-.39-1.569c.09-.271.254-.513.475-.698.22-.185.49-.306.776-.35L8.66 7.73l1.925-3.862c.128-.26.328-.48.577-.633a1.584 1.584 0 0 1 1.662 0c.25.153.45.373.577.633l1.925 3.847 4.334.615c.29.042.562.162.785.348.224.186.39.43.48.704a1.514 1.514 0 0 1-.404 1.58l-3.13 3 .736 4.247c.047.282.014.572-.096.837-.111.265-.294.494-.53.662a1.582 1.582 0 0 1-1.643.117l-3.865-2-3.865 2z"></path>
                      </svg>
                      <span class="ml-2">Avaliação</span>
                    </div>
                </span>
              </div>
            </div>
          </div>
      </div>
  </section>

  </div>
</template>
  
<script>
  import Carrossel from "@/components/Carrossel.vue"
  export default {
    components: { Carrossel },
    data(){
      return {
        feedbacks: null,
        acoes: null
      }
    },
    methods: {
      movimentacaoFeed(){
        document.addEventListener('DOMContentLoaded', function () {
          const swiper = new Swiper('.swiper-container-feedBack', {
            slidesPerView: 1.5,
            spaceBetween: 32,
            centeredSlides: true,
            // slideActiveClass: "", //NÃO APAGA
            autoplay: {
              delay: 8000,
            },
            breakpoints: {
              1024: {
                slidesPerView: 3.5,
              }      
            },                
          })
        })
      },
      async getFeedbacks() {
        const req = await fetch(`${process.env.VUE_APP_URL}/feedback`);
        const data = await req.json();

        this.feedbacks = data;
      },
      async getAcoes() {
        const req = await fetch(`${process.env.VUE_APP_URL}/acoes`);
        const data = await req.json();

        this.acoes = data;
      }

    },
    mounted() {
      this.getFeedbacks()
      this.getAcoes()
      this.movimentacaoFeed() 
    }
  }
   
   
</script>

<style scoped>

</style>