<template>
  <section class="slider relative">    
    <div class="slider-content flex relative h-44 rounded-lg" :id="('carrossel_'+index_carrossel)">
      <div  v-for="(imagem, index) in imagens" :key="index" class="slider-item">
        <div class="ease-in-out" data-carousel-item>
          <img :src="'../img' + imagem.src" class=" absolute block w-full -translate-y-1/2 top-1/2">
        </div>
      </div>
    </div>
    <button v-if="this.translateX > 0" @click="previousCarrossel()" type="button" class="prev-button-carrossel absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none">
        <span class="inline-flex items-center justify-center w-5 h-5 border rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-white group-focus:outline-none">
            <svg aria-hidden="true" class="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <span class="sr-only">Previous</span>
        </span>
      </button>
      <button v-if="this.translateX < (this.imagens.length * 100 - 100 )" @click="nextCarrossel()" type="button" class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none">
          <span class="inline-flex items-center justify-center w-5 h-5 border rounded-full bg-white/30 group-hover:bg-white/50  group-focus:ring-white group-focus:outline-none">
              <svg aria-hidden="true" class="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              <span class="sr-only">Next</span>
          </span>
      </button>   
  </section>
</template>

<script>
  export default {
    props: {
      index_carrossel: Number,
      imagens: Array
    },
    data(){
      return {
        translateX: 0
      }
    },
    methods: {
      nextCarrossel(){
        if((this.imagens.length * 100) == this.translateX) return
      
        this.translateX += 100;
        document.getElementById("carrossel_" + this.index_carrossel).style.transform = "translateX(-" + this.translateX + "%)"; 
      },
      previousCarrossel(){
        if(this.translateX == 0) return
        
        this.translateX -= 100;
        document.getElementById("carrossel_" + this.index_carrossel).style.transform = "translateX(-" + this.translateX + "%)"; 
      }
    },
  }
   
   
</script>

<style scoped>
    .slider{
        overflow: hidden;
    
    }
    .slider > .slider-content{
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        transition: all 500ms ease;
    }
    
    .slider > .slider-content > .slider-item {
        flex: 0 0 auto;
        width: 100%;
    }
    

</style>