import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/global.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueGoogleMaps from '@fawmi/vue-google-maps'

createApp(App)
    .use(router, AOS.init({ duration: 1500 }))
    .use(VueGoogleMaps, {load: {key: 'AIzaSyCPAFYK4wAPYCl2gNkdSPQFtA93LkVHHjg'}})
    .mount('#app')
