import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BemVindo = _resolveComponent("BemVindo")!
  const _component_Historia = _resolveComponent("Historia")!
  const _component_Qualidades = _resolveComponent("Qualidades")!
  const _component_Galeria = _resolveComponent("Galeria")!
  const _component_Feedback = _resolveComponent("Feedback")!
  const _component_Localizacao = _resolveComponent("Localizacao")!
  const _component_Orcamento = _resolveComponent("Orcamento")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BemVindo),
    _createVNode(_component_Historia),
    _createVNode(_component_Qualidades),
    _createVNode(_component_Galeria),
    _createVNode(_component_Feedback),
    _createVNode(_component_Localizacao),
    _createVNode(_component_Orcamento)
  ], 64))
}