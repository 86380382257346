
import { Options, Vue } from 'vue-class-component'; // Desconheço
import BemVindo from '@/components/BemVindo.vue';
import Historia from '@/components/Historia.vue';
import Qualidades from '@/components/Qualidades.vue';
import Galeria from '@/components/Galeria.vue';
import Feedback from '@/components/Feedback.vue';
import Localizacao from '@/components/Localizacao.vue';
import Orcamento from '@/components/Orcamento.vue';

@Options({
  components: { BemVindo, Historia, Qualidades, Galeria, Feedback, Localizacao, Orcamento},
})
export default class HomeView extends Vue {}
