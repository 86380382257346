
export default {
    name: "Navbar",
    data() {
      return {
        showDropdownNav: 1
      }
    },
    props: ["logo", "alt"]
    }
