<template>
  <section id="localizacao relative">
    <div class="flex bg-white absolute z-50 ml-3 mt-3 shadow-lg">
      <ul id="m-0 p-0">
        <li class="border relative text-left p-5 pr-10">
          <button class="mb-2 sm:font-semibold font-medium">
            <h2>Planeta Piscinas Bauru</h2>
          </button>
          <address class="w-60 text-[0.75rem] sm:text-sm not-italic">Av. Nossa Sra. de Fátima<br>20-133 - Jardim America, Bauru - SP, 17017-339, Brasil</address>
          <a class="absolute right-5 top-8" href="https://www.google.com/maps/dir/?api=1&amp;origin=&amp;destination=Planeta+Piscinas+Bauru%2CAv.+Nossa+Sra.+de+F%C3%A1tima%2C20-133+-+Jardim+America%2C+Bauru+-+SP%2C+17017-339%2C+Brasil" target="_blank" title="Get directions to this location on Google Maps">
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.5867 9.24375L17.9403 8.8902V8.8902L17.5867 9.24375ZM16.4117 9.24375L16.7653 9.59731L16.7675 9.59502L16.4117 9.24375ZM8.91172 16.7437L8.55817 16.3902L8.91172 16.7437ZM8.91172 17.9229L8.55817 18.2765L8.55826 18.2766L8.91172 17.9229ZM16.4117 25.4187H16.9117V25.2116L16.7652 25.0651L16.4117 25.4187ZM16.4117 25.4229H15.9117V25.63L16.0582 25.7765L16.4117 25.4229ZM25.0909 17.9229L25.4444 18.2765L25.4467 18.2742L25.0909 17.9229ZM25.4403 16.3902L17.9403 8.8902L17.2332 9.5973L24.7332 17.0973L25.4403 16.3902ZM17.9403 8.8902C17.4213 8.3712 16.5737 8.3679 16.0559 8.89248L16.7675 9.59502C16.8914 9.4696 17.1022 9.4663 17.2332 9.5973L17.9403 8.8902ZM16.0582 8.8902L8.55817 16.3902L9.26527 17.0973L16.7653 9.5973L16.0582 8.8902ZM8.55817 16.3902C8.0379 16.9105 8.0379 17.7562 8.55817 18.2765L9.26527 17.5694C9.13553 17.4396 9.13553 17.227 9.26527 17.0973L8.55817 16.3902ZM8.55826 18.2766L16.0583 25.7724L16.7652 25.0651L9.26517 17.5693L8.55826 18.2766ZM15.9117 25.4187V25.4229H16.9117V25.4187H15.9117ZM16.0582 25.7765C16.5784 26.2967 17.4242 26.2967 17.9444 25.7765L17.2373 25.0694C17.1076 25.1991 16.895 25.1991 16.7653 25.0694L16.0582 25.7765ZM17.9444 25.7765L25.4444 18.2765L24.7373 17.5694L17.2373 25.0694L17.9444 25.7765ZM25.4467 18.2742C25.9631 17.7512 25.9663 16.9096 25.438 16.3879L24.7354 17.0995C24.8655 17.2279 24.8687 17.4363 24.7351 17.5716L25.4467 18.2742Z" fill="#1967d2"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19 19.8333V17.75H15.6667V20.25H14V16.9167C14 16.4542 14.3708 16.0833 14.8333 16.0833H19V14L21.9167 16.9167L19 19.8333Z" fill="#1967d2"></path>
              <circle class="directions-button-background" cx="17" cy="17" r="16.5" stroke="#1967d2"></circle>
            </svg>
          </a>
        </li>
    </ul>
  </div>
  <GMapMap 
  :center="center" 
  :zoom="16" 
  map-type-id="terrain" 
  style="width: 100vw; height: 20rem"
  :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: false,
      }">
        <GMapMarker 
        :key="index" v-for="(m, index) in markers" 
        :position="m.position"
        :clickable="true" 
        :draggable="false"
        @click="openMarker(m.id)" >
              <GMapInfoWindow
              :closeclick="true"
              @closeclick="openMarker(null)"
              @click.self="openMarker(null)"
              :opened="openedMarkerID == m.id">
                  <div>
                    <div class="mb-2 font-semibold">
                      <h2>Planeta Piscinas Bauru</h2>
                    </div>
                    <address class="w-60 text-sm not-italic">Av. Nossa Sra. de Fátima<br>20-133 - Jardim America, Bauru - SP, 17017-339, Brasil</address>
                    <div class="text-blue-600 hover:underline">
                      <a  href="https://www.google.com/maps/dir/?api=1&amp;origin=&amp;destination=Planeta+Piscinas+Bauru%2CAv.+Nossa+Sra.+de+F%C3%A1tima%2C20-133+-+Jardim+America%2C+Bauru+-+SP%2C+17017-339%2C+Brasil" >
                        <span>Visualize no Google Maps</span>
                      </a>
                    </div>
                  </div>
              </GMapInfoWindow>
        </GMapMarker>
  </GMapMap>
</section>
</template>
<script>
  export default {
  name: 'Localizacao',
  data() {
    return {
      openedMarkerID: null,
      center: { lat: -22.35319013503694, lng: -49.05055605134456 },
      markers: [
        {
          id: 1,
          position: {
            lat: -22.35319013503694,
            lng: -49.05055605134456
          }
        },
        // {
        //    id: 2,
        //   position: {
        //     lat: 51.198429,
        //     lng: 6.69529
        //   }
        // }
      ]
    };
  },
  methods: {
    openMarker(id) {
        this.openedMarkerID = null;
        setTimeout(() => {
          this.openedMarkerID = id;
        }, 100);
    }
  }
};
</script>

<style scoped>

  body {
    margin: 0;
  }

</style>